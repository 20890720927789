import { lazy, Fragment } from "react";
import { Row, Col } from "antd";
import i18n from "i18next";
import { withTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

import * as S from "./styles";

const SvgIcon = lazy(() => import("../../common/SvgIcon"));
const Container = lazy(() => import("../../common/Container"));

const Footer = ({ t }) => {
  const handleChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  const openPS_Mkt = () => {
    var PKG_NAME = "com.greyscaleapps.saatsurveer";
    var hrefGoTo = ""
    if (navigator.userAgent.match(/android/i)) {
      hrefGoTo = "market://details?id="+PKG_NAME+"&ddl=1&pcampaignid=web_site_self&dr=self_site";
    } else {
      hrefGoTo = "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id="+PKG_NAME+"&ddl=1&pcampaignid=web_site_self&dr=self_site"
    }
    window.location.href = hrefGoTo;
  };

  const SocialLink = ({ href, src }) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="35px" height="35px" />
      </a>
    );
  };

  return (
    <Fragment>
      <Fade bottom>
        <S.Extra>
          <Container border="true">
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              style={{ paddingTop: "3rem" }}
            >
              
              <S.LogoContainer onClick={openPS_Mkt}>
                <SvgIcon
                  src="logo.png"
                  aria-label="homepage"
                  height="60px"
                />
              </S.LogoContainer>
              <S.FooterContainer>
                <SocialLink
                  href="https://www.youtube.com/channel/UCpsD5c1JAY7rPPyOhFX_MSw/"
                  src="youtube_colored.svg"
                />
                <SocialLink
                  href="https://www.facebook.com/7SurVeer"
                  src="facebook.svg"
                />
                <SocialLink
                  href="https://instagram.com/7surveer"
                  src="instagram_colored.svg"
                />
                <SocialLink
                  href="https://twitter.com/7surveer"
                  src="twitter.svg"
                />
              </S.FooterContainer>
            </Row>
          </Container>
        </S.Extra>
      </Fade>
    </Fragment>
  );
};

export default withTranslation()(Footer);
