import { useState, Fragment, lazy } from "react";
import { Row, Col, Drawer } from "antd";
import { CSSTransition } from "react-transition-group";
import { withTranslation } from "react-i18next";

import * as S from "./styles";

const SvgIcon = lazy(() => import("../../common/SvgIcon"));
const Button = lazy(() => import("../../common/Button"));

const Header = ({ t }) => {
  const [isNavVisible] = useState(false);
  const [isSmallScreen] = useState(false);
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = (id) => {
      const element = document.getElementById(id);
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };
    const openPS_Mkt = () => {
      var PKG_NAME = "com.greyscaleapps.saatsurveer";
      var hrefGoTo = ""
      if (navigator.userAgent.match(/android/i)) {
        hrefGoTo = "market://details?id="+PKG_NAME+"&ddl=1&pcampaignid=web_site_self&dr=self_site";
      } else {
        hrefGoTo = "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id="+PKG_NAME+"&ddl=1&pcampaignid=web_site_self&dr=self_site"
      }
      window.location.href = hrefGoTo;
    };
    return (
      <Fragment>
        <S.CustomNavLinkSmall onClick={() => scrollTo("pp")}>
          <S.Span>{t("Pitch Plotter")}</S.Span>
        </S.CustomNavLinkSmall>
        <S.CustomNavLinkSmall onClick={() => scrollTo("instru")}>
          <S.Span>{t("Instruments on Keyboard")}</S.Span>
        </S.CustomNavLinkSmall>
        <S.CustomNavLinkSmall onClick={() => scrollTo("rg")}>
          <S.Span>{t("Raagas Section")}</S.Span>
        </S.CustomNavLinkSmall>
        <S.CustomNavLinkSmall onClick={() => scrollTo("et")}>
          <S.Span>{t("Ear Trainer")}</S.Span>
        </S.CustomNavLinkSmall>
        <S.CustomNavLinkSmall
          onClick={openPS_Mkt}
          style={{ width: "180px" }}
        >
          <S.Span>
            <img  style={{width:"80%", position: "inherit"}} to="/" src="./google_play.png"/>
          </S.Span>
        </S.CustomNavLinkSmall>
      </Fragment>
    );
  };

  return (
    <S.Header>
      <S.Container>
        <Row type="flex" justify="space-between" >
          <Col lg={1} md={1} sm={2} xs={5}>
            <S.LogoContainer style={{width:"100%", position: "inherit", float: "left"}} to="/" aria-label="homepage">
              <img src="/img/svg/logo.png" style={{ width:"100%", position: "inherit", float: "left"}} />
            </S.LogoContainer>
          </Col>
          <Col lg={20} md={20} sm={8} xs={8}>
          <Row type="flex" justify="end" style={{fontWeight:"600"}}>
            <S.NotHidden>
              <MenuItem />
            </S.NotHidden>
            <S.Burger onClick={showDrawer}>
              <S.Outline />
            </S.Burger>
          </Row>
          </Col>
        </Row>
        <CSSTransition
          in={!isSmallScreen || isNavVisible}
          timeout={350}
          classNames="NavAnimation"
          unmountOnExit
        >
          <Drawer closable={false} visible={visible} onClose={onClose}>
            <Col style={{ marginBottom: "2.5rem" }}>
              <S.Label onClick={onClose}>
                <Col span={12}>
                  <S.Menu>Menu</S.Menu>
                </Col>
                <Col span={12}>
                  <S.Outline padding="true" />
                </Col>
              </S.Label>
            </Col>
            <MenuItem />
          </Drawer>
        </CSSTransition>
      </S.Container>
    </S.Header>
  );
};

export default withTranslation()(Header);
